import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ColDef } from "ag-grid-community";
import { AuthenticationService } from "src/app/services/authentication.service";
import { QualificationService } from "src/app/shared/generated/api/qualification.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Subscription } from "rxjs";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { EsaMaterialButtonComponent } from "esa-material-form-field";
import { TalentBridgeGridComponent } from "src/app/shared/components/talentbridge-grid/talentbridge-grid.component";
import { CustomRichTextComponent } from "src/app/shared/components/custom-rich-text/custom-rich-text.component";
import { MatIcon } from "@angular/material/icon";
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { QualificationStatusEnum } from "src/app/shared/generated/enum/qualification-status-enum";
import { QualificationReviewDialog } from "../shared/qualification-review-dialog/qualification-review-dialog.component";
import { ButtonRendererComponent } from "src/app/shared/components/ag-grid/button-renderer/button-renderer.component";

@Component({
    selector: "talentbridge-qualification-review",
    templateUrl: "./qualification-review.component.html",
    styleUrls: ["./qualification-review.component.scss"],
    standalone: true,
    imports: [
        CustomRichTextComponent,
        EsaMaterialButtonComponent,
        TalentBridgeGridComponent,
        MatIcon
    ]
})
export class QualificationReviewComponent implements OnInit, OnDestroy {

    private currentUser: UserDto;

    public rowData = [];
    columnDefs: ColDef[];
    rejectedRowData = [];
    rejectedColumnDefs: ColDef[];

    userSubscription: Subscription;
    qualificationsSubscription: Subscription;

    constructor(
        private cdr: ChangeDetectorRef,
        private authenticationService: AuthenticationService,
        private qualificationService: QualificationService,
        public dialog: MatDialog,
        private alertService: AlertService,
    ) {
    }

    ngOnInit() {
        this.userSubscription = this.authenticationService
            .getCurrentUser()
            .subscribe((currentUser) => {
                this.currentUser = currentUser;
                this.createColumnDefs();
                this.cdr.markForCheck();
            });
    }

    createColumnDefs() {
        this.columnDefs = [
            {
                cellRenderer: ButtonRendererComponent,
                cellRendererParams: {
                    onClick: this.openQualificationReviewDialog.bind(this),
                    icon: "task",
                },
                cellStyle: (params) => {
                    return { "text-overflow": "unset" };
                },
                filter: null,
                sortable: false,
                width: 65,
                colId: "review"
            },
            {
                headerName: "Service Line",
                field: "Service.ServiceCategory.ServiceLine.Name",
                flex: 2,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "Service.ServiceCategory.ServiceLine.Name",
                },
                tooltipField: "Service.ServiceCategory.ServiceLine.Name",
            },
            {
                headerName: "Service Category",
                field: "Service.ServiceCategory.Name",
                flex: 3,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "Service.ServiceCategory.Name",
                },
                tooltipField: "Service.ServiceCategory.Name",
            },
            {
                headerName: "Service",
                field: "Service.Name",
                flex: 3,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "Service.Name",
                },
                tooltipField: "Service.Name",
            },
            {
                headerName: "Name",
                field: "Name",
                flex: 3,
                tooltipField: "$NameAndDescription",
                wrapText: true,
            },
            {
                headerName: "Type",
                field: "QualificationType.Name",
                flex: 2,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "QualificationType.Name",
                },
                tooltipField: "QualificationType.Name",
            },
            {
                headerName: "Suggested By",
                field: "SubmitUser.FullName",
                flex: 2,
                tooltipField: "SubmitUser.FullName",
            },
        ];

        this.rejectedColumnDefs = [
            {
                headerName: "Service Line",
                field: "Service.ServiceCategory.ServiceLine.Name",
                flex: 2,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "Service.ServiceCategory.ServiceLine.Name",
                },
                tooltipField: "Service.ServiceCategory.ServiceLine.Name",
            },
            {
                headerName: "Service Category",
                field: "Service.ServiceCategory.Name",
                flex: 2,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "Service.ServiceCategory.Name",
                },
                tooltipField: "Service.ServiceCategory.Name",
            },
            {
                headerName: "Service",
                field: "Service.Name",
                flex: 2,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "Service.Name",
                },
                tooltipField: "Service.Name",
            },
            {
                headerName: "Name",
                field: "Name",
                flex: 2,
                tooltipField: "$NameAndDescription",
                wrapText: true,
            },
            {
                headerName: "Type",
                field: "QualificationType.Name",
                flex: 2,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "QualificationType.Name",
                },
                tooltipField: "QualificationType.Name",
            },
            {
                headerName: "Suggested By",
                field: "SubmitUser.FullName",
                flex: 2,
                tooltipField: "SubmitUser.FullName",
            },
            {
                headerName: "Rejection Reason",
                field: "DecisionJustification",
                flex: 3,
                tooltipField: "DecisionJustification",
            },
        ];
    }

    onQualificationReviewGridReady(gridEvent) {
        gridEvent.api.showLoadingOverlay();
        this.refreshData(gridEvent);
    }

    ngOnDestroy() {
        this.cdr.detach();
        this.userSubscription?.unsubscribe();
        this.qualificationsSubscription?.unsubscribe();
    }

    openQualificationReviewDialog(event) {
        let qualification = event.rowData;
        const dialogRef = this.dialog.open(QualificationReviewDialog, {
            data: {
                Qualification: qualification
            }
        });

        return dialogRef.afterClosed().subscribe((dialogResult) => {
            if (dialogResult) {
                let qualificationApprovalDto = dialogResult.QualificationApprovalDto;

                if (dialogResult.Approve) {
                    this.qualificationService.qualificationsQualificationIDApprovePut(qualification.QualificationID, qualificationApprovalDto).subscribe((result) => {
                        this.alertService.pushAlert(new Alert("The suggested qualification was successfully approved.", AlertContext.Success), 5000);
                        this.refreshData();
                    },
                    (error) => {
                        this.alertService.pushAlert(new Alert(`There was an error approving the suggested qualification. Please try again.`, AlertContext.Danger, true));
                    });
                } else {
                    this.qualificationService.qualificationsQualificationIDRejectPut(qualification.QualificationID, qualificationApprovalDto).subscribe((result) => {
                        this.alertService.pushAlert(new Alert("The suggested qualification was successfully rejected.", AlertContext.Success), 5000);
                        this.refreshData();
                    },
                    (error) => {
                        this.alertService.pushAlert(new Alert(`There was an error rejecting the suggested qualification. Please try again.`, AlertContext.Danger, true));
                    });
                }
            }
        });
    }

    refreshData(gridEvent?: any) {
        this.qualificationsSubscription = this.qualificationService
        .qualificationsGet()
        .subscribe((qualifications) => {
            qualifications = qualifications.map((qual) => {
                qual["$NameAndDescription"] = qual.Description ? `${qual.Name}: ${qual.Description}` : qual.Name;
                return qual;
            });

            this.rowData = qualifications.filter((q) => {
                return q.QualificationStatus.QualificationStatusID === QualificationStatusEnum.Submitted;
            });

            this.rejectedRowData = qualifications.filter((q) => {
                return q.QualificationStatus.QualificationStatusID === QualificationStatusEnum.Rejected;
            });

            gridEvent?.api.hideOverlay();
            this.cdr.markForCheck();
        });
    }
}
