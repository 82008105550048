<div class="dashboard">
  <aside class="sidebar">
    <div class="sidebar-header">
      <h5 class="sidebar-title">
        <a routerLink="/manage">Manage Dashboard</a>
      </h5>
    </div>

    <ul class="sidebar-nav">
      @if (userCanEditConfiguration) {
        <li class="sidebar-item">
          <a [routerLink]="['configuration']" routerLinkActive="active" class="sidebar-link"><i class="fa fa-gear"></i>Configuration</a>
        </li>
      }
      @if (userCanEditUsers) {
        <li class="sidebar-item">
          <a [routerLink]="['users']" routerLinkActive="active" class="sidebar-link"><i class="fa fa-user"></i>Users</a>
        </li>
      }
      @if (userCanEditFieldDefinitions) {
        <li class="sidebar-item">
          <a [routerLink]="['labels-and-definitions']" routerLinkActive="active" class="sidebar-link"><i class="fa fa-list-ul"></i>Labels & Definitions</a>
        </li>
      }
      @if (userCanEditCustomPages) {
        <li class="sidebar-item">
          <a [routerLink]="['custom-pages']" routerLinkActive="active" class="sidebar-link"><i class="fa fa-file-alt"></i>Custom Pages</a>
        </li>
      }
      @if (userCanEditQualifications) {
        <li class="sidebar-item">
          <a [routerLink]="['qualification-review']" routerLinkActive="active" class="sidebar-link"><i class="fa fa-search"></i>Qualification Review</a>
        </li>
      }
    </ul>
  </aside>

  <main class="main">
    <router-outlet (activate)="onActivate($event)" (deactivate)="onDeactivate($event)"></router-outlet>

    @if (!hideText) {
    <div class="list-view">
      <div class="page-header">
        <h1 class="page-title">Manage Dashboard</h1>
      </div>
    </div>
    }
  </main>
</div>