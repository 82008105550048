import {
    MAT_DIALOG_DATA,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogRef,
} from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { QualificationDto } from 'src/app/shared/generated/model/qualification-dto';
import { EsaMaterialButtonComponent, EsaMaterialFormFieldComponent } from 'esa-material-form-field';
import { QualificationApprovalDto } from "src/app/shared/generated/model/qualification-approval-dto";

@Component({
    selector: "talentbridge-qualification-review-dialog",
    templateUrl: "qualification-review-dialog.component.html",
    styleUrls: ["./qualification-review-dialog.component.scss"],
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        MatIconModule,
        EsaMaterialButtonComponent,
        EsaMaterialFormFieldComponent,
        FormsModule
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QualificationReviewDialog {
    @ViewChild("qualificationReviewForm", { read: NgForm }) form: NgForm;

    qualificationApprovalDto: QualificationApprovalDto;
    qualification: QualificationDto;
     
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<QualificationReviewDialog>,
        private cdr: ChangeDetectorRef,
    ) {}
    
    ngOnInit(): void {
        this.qualificationApprovalDto = new QualificationApprovalDto();
        this.qualification = this.data.Qualification;
        this.cdr.detectChanges();
    }

    cancel() {
        this.dialogRef.close(null);
        return;
    }

    approve(isApproved: boolean) {
        this.dialogRef.close({
            Approve: isApproved,
            QualificationApprovalDto: this.qualificationApprovalDto,
        });
        return;
    }
}
