<nav>
    <div class="main-nav">
        <a routerLink="/" routerLinkActive="active" class="site-logo">
            <svg
                viewBox="0 0 180 136"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7.8396 120.817H12.172C14.0287 120.817 15.2665 121.229 15.8854 121.951C16.5044 122.673 16.917 123.602 16.917 124.633C16.917 125.665 16.8138 125.768 16.5044 126.284C16.2981 126.696 15.8854 127.212 15.3697 127.625C16.4012 127.934 17.1233 128.553 17.5359 129.172C17.9485 129.894 18.1548 130.616 18.1548 131.441C18.1548 132.782 17.639 133.917 16.7107 134.639C15.7823 135.361 14.4413 135.774 12.7909 135.774H7.8396V120.92V120.817ZM11.1405 123.292V126.593H11.8625C12.3783 126.593 12.894 126.387 13.2035 126.077C13.6161 125.768 13.7193 125.355 13.7193 124.84C13.7193 124.324 13.513 124.117 13.2035 123.808C12.7909 123.499 12.3783 123.395 11.8625 123.395H11.1405V123.292ZM11.1405 129.069V133.195H12.2751C14.0287 133.195 14.9571 132.576 14.9571 131.235C14.9571 129.894 14.7508 129.997 14.3382 129.585C13.9256 129.172 13.3066 129.069 12.4814 129.069H11.1405Z"
                    fill="#454040"
                />
                <path
                    d="M38.7852 120.817H43.7364C45.1806 120.817 46.4184 121.229 47.2436 121.951C48.0688 122.673 48.5846 123.808 48.5846 125.149C48.5846 126.49 48.3783 126.696 47.9657 127.521C47.5531 128.347 46.831 128.966 45.7995 129.481L49.9256 135.67H46.2121L42.3955 129.894H41.9829V135.67H38.7852V120.817ZM41.9829 123.395V127.315H42.7049C44.3554 127.315 45.1806 126.593 45.1806 125.252C45.1806 123.911 44.9743 124.221 44.5617 123.911C44.1491 123.602 43.5301 123.395 42.7049 123.395H41.9829Z"
                    fill="#454040"
                />
                <path
                    d="M70.8654 120.817H74.0631V135.67H70.8654V120.817Z"
                    fill="#454040"
                />
                <path
                    d="M95.5187 120.817H100.573C102.739 120.817 104.493 121.539 105.834 122.88C107.175 124.221 107.794 126.077 107.794 128.244C107.794 130.41 107.072 132.266 105.731 133.607C104.39 134.948 102.533 135.67 100.264 135.67H95.4155V120.817H95.5187ZM98.8195 123.602V132.782H100.057C101.398 132.782 102.43 132.473 103.255 131.751C104.08 131.029 104.493 129.791 104.493 128.14C104.493 126.49 104.287 126.284 103.874 125.562C103.461 124.84 102.842 124.324 102.12 124.014C101.295 123.705 100.573 123.602 99.8511 123.602H98.7164H98.8195Z"
                    fill="#454040"
                />
                <path
                    d="M141.628 124.014L139.461 125.974C139.049 125.252 138.43 124.633 137.811 124.221C137.192 123.808 136.47 123.602 135.748 123.602C134.613 123.602 133.582 124.014 132.756 124.943C131.931 125.871 131.519 126.903 131.519 128.037C131.519 129.172 131.931 130.41 132.756 131.338C133.582 132.266 134.613 132.679 135.851 132.679C137.089 132.679 137.192 132.473 137.708 132.163C138.327 131.854 138.842 131.235 139.152 130.307H135.645V127.418H142.556V129.378C142.556 130.41 142.246 131.441 141.524 132.576C140.802 133.711 139.977 134.536 138.842 135.052C137.708 135.567 136.573 135.877 135.438 135.877C133.375 135.877 131.622 135.155 130.178 133.607C128.734 132.06 128.011 130.307 128.011 128.037C128.011 125.768 128.734 124.221 130.178 122.673C131.622 121.229 133.375 120.507 135.438 120.507C137.501 120.507 140.08 121.642 141.524 123.911L141.628 124.014Z"
                    fill="#454040"
                />
                <path
                    d="M163.599 120.817H171.954V123.808H166.797V126.799H171.954V129.688H166.797V132.782H171.954V135.774H163.599V120.92V120.817Z"
                    fill="#454040"
                />
                <path
                    d="M2.99146 79.659H21.043V85.3324H14.8539V106.479H9.07741V85.3324H2.99146V79.659Z"
                    fill="#454040"
                />
                <path
                    d="M39.9198 79.659H44.4585L55.3926 106.582H49.5129L47.3467 101.321H37.0316L34.9685 106.582H29.192L40.023 79.659H39.9198ZM42.086 88.4269L38.8883 96.3696H45.2837L42.086 88.4269Z"
                    fill="#454040"
                />
                <path
                    d="M68.0802 79.659H73.8567V100.805H81.7994V106.582H68.0802V79.659Z"
                    fill="#454040"
                />
                <path
                    d="M94.384 79.659H109.547V85.0229H100.16V90.49H109.547V95.7507H100.16V101.321H109.547V106.685H94.384V79.7622V79.659Z"
                    fill="#454040"
                />
                <path
                    d="M140.802 79.659H146.476V106.582H140.802L128.527 89.149V106.582H122.751V79.659H128.527L140.905 96.9885V79.659H140.802Z"
                    fill="#454040"
                />
                <path
                    d="M158.544 79.659H176.596V85.3324H170.407V106.479H164.63V85.3324H158.544V79.659Z"
                    fill="#454040"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M162.155 29.4241C163.186 30.4556 164.115 31.4871 165.043 32.4155C128.94 -0.696272 72.8252 0.23209 37.9599 35.2006C30.8424 42.3181 25.1691 50.3639 20.8367 58.8224H0C4.84814 48.0946 11.6562 38.0888 20.4241 29.3209C59.5186 -9.77364 123.06 -9.77364 162.155 29.3209V29.4241Z"
                    fill="#F89F33"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M165.559 39.7392C171.438 45.6189 176.183 52.0143 180 58.9255H154.625C151.84 54.5931 148.539 50.467 144.722 46.6504C116.252 18.1805 70.7622 17.149 41.0544 43.3496C42.1891 42.1117 43.3238 40.8739 44.5616 39.6361C77.9828 6.21489 132.241 6.21489 165.662 39.6361L165.559 39.7392Z"
                    fill="#F48256"
                />
            </svg>
        </a>

        <p class="main-nav__header">Our Talent</p>
        <ul class="main-nav__list">
            @if (userCanViewEmployees) {
                <li class="nav-item">
                    <a
                        routerLink="/employees"
                        class="nav-link"
                        role="button"
                        routerLinkActive="active"
                    >
                        <mat-icon fontSet="material-icons-outlined"
                            >badge</mat-icon
                        >Employee-Owners
                    </a>
                </li>
            }

            @if (userCanViewQualifications) {
                <li class="nav-item">
                    <a
                        routerLink="/qualifications"
                        class="nav-link"
                        role="button"
                        routerLinkActive="active"
                    >
                        <mat-icon fontSet="material-icons-outlined"
                            >military_tech</mat-icon
                        >Skills & Qualifications
                    </a>
                </li>
            }
        </ul>

        <p class="main-nav__header">Our Work</p>
        <ul class="main-nav__list">
            @if (userCanViewProjects) {
                <li class="nav-item">
                    <a
                        routerLink="/projects"
                        class="nav-link"
                        role="button"
                        routerLinkActive="active"
                    >
                        <mat-icon fontSet="material-icons-outlined"
                            >eco</mat-icon
                        >Projects
                    </a>
                </li>
            }

            @if (userCanManageDashboard) {
                <li class="nav-item">
                    <a
                        routerLink="/service-lines"
                        class="nav-link"
                        role="button"
                        routerLinkActive="active"
                    >
                        <mat-icon fontSet="material-icons-outlined"
                            >view_timeline</mat-icon
                        >Service Lines
                    </a>
                </li>
            }
            @if (userCanManageDashboard) {
                <li class="nav-item">
                    <a
                        routerLink="/service-categories"
                        class="nav-link"
                        role="button"
                        routerLinkActive="active"
                    >
                        <mat-icon fontSet="material-icons-outlined"
                            >category</mat-icon
                        >Service Categories
                    </a>
                </li>
            }
            @if (userCanManageDashboard) {
                <li class="nav-item">
                    <a
                        routerLink="/services"
                        class="nav-link"
                        role="button"
                        routerLinkActive="active"
                    >
                        <mat-icon fontSet="material-icons-outlined"
                            >design_services</mat-icon
                        >Services
                    </a>
                </li>
            }
            @if (userCanViewClients) {
                <li class="nav-item">
                    <a
                        routerLink="/clients"
                        class="nav-link"
                        role="button"
                        routerLinkActive="active"
                    >
                        <mat-icon fontSet="material-icons-outlined"
                            >account_balance</mat-icon
                        >Clients
                    </a>
                </li>
            }
        </ul>

        <p class="main-nav__header disabled">Workspace</p>
        <ul class="main-nav__list">
            @if (userCanViewProjects) {
                <li class="nav-item">
                    <a
                        class="nav-link disabled"
                        role="button"
                        routerLinkActive="active"
                    >
                        <mat-icon fontSet="material-icons-outlined"
                            >lightbulb</mat-icon
                        >My Proposals
                    </a>
                </li>
            }
            @if (userCanViewProjects) {
                <li class="nav-item">
                    <a
                        class="nav-link disabled"
                        role="button"
                        routerLinkActive="active"
                    >
                        <mat-icon fontSet="material-icons-outlined"
                            >add</mat-icon
                        >Create a Proposal
                    </a>
                </li>
            }
        </ul>
    </div>

    <ul class="utilities">
        @if (currentUser) {
            @if (userCanViewEmployees) {
                <li class="nav-item">
                    <a
                        routerLink="/employees/{{ this.currentUser?.UserID }}"
                        class="nav-link"
                        role="button"
                        routerLinkActive="active"
                    >
                        <mat-icon fontSet="material-icons-outlined"
                            >account_circle</mat-icon
                        >Profile
                    </a>
                </li>
            }
            @if (userCanManageDashboard) {
                <li class="nav-item">
                    <a
                        routerLink="/manage"
                        class="nav-link"
                        role="button"
                        routerLinkActive="active"
                    >
                        <mat-icon fontSet="material-icons-outlined"
                            >tune</mat-icon
                        >Admin
                    </a>
                </li>
            }
            <!-- TODO: Do we need custom pages? -->
            <!-- <li class="nav-item dropdown" routerLinkActive="active" *ngIf="viewPages.length > 0">
      <a href="javascript:void(0);" [dropdownToggle]="viewToggle" class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        View
      </a>
      <div #viewToggle class="dropdown-menu" aria-labelledby="navbarDropdown">
        <a *ngFor="let viewPage of viewPages" routerLink="/custom-pages/{{ viewPage.CustomPageVanityUrl }}" class="dropdown-item">
          {{ viewPage.DisplayName }}
        </a>
      </div>
    </li> -->
            <li class="user-button" data-cy="userName">
                <a
                    class="user-button__link"
                    routerLink="/employees/{{ this.currentUser?.UserID }}"
                >
                    <img
                        class="user-button__image"
                        src="{{ mainAppApiUrl }}/users/{{
                            currentUser.UserID
                        }}/photoThumbnail?uid={{
                            currentUser.UserGuid
                        }}&secure={{ currentUser.FileAccessToken }}"
                    />
                    <span class="user-button__name">{{
                        currentUser?.LoginName
                    }}</span>
                </a>
                <button
                    class="user-button__menu"
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                    aria-label="User Menu"
                >
                    <mat-icon>more_horiz</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <a
                        mat-menu-item
                        href="javascript:void(0);"
                        (click)="logout()"
                        ><mat-icon>logout</mat-icon
                        >{{
                            userBeingImpersonated
                                ? "Stop Impersonating"
                                : "Sign Out"
                        }}</a
                    >
                    <a
                        mat-menu-item
                        routerLink="/help"
                        class="nav-link"
                        routerLinkActive="active"
                    >
                        <mat-icon fontSet="material-icons-outlined"
                            >help</mat-icon
                        >Help
                    </a>
                </mat-menu>
            </li>
        } @else {
            <li routerLinkActive="active" class="user-button">
                <a
                    href="javascript:void(0);"
                    (click)="login()"
                    routerLinkActive="active"
                    class="nav-link"
                    data-cy="signIn"
                    ><mat-icon>login</mat-icon><span>Sign In</span></a
                >
            </li>
        }
    </ul>
</nav>
