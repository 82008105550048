import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
} from "@angular/core";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CurrentEmployeeService } from "src/app/services/current-employee.service";
import { EditViewEventService } from "src/app/services/edit-view-event.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { EmployeeDto } from "src/app/shared/generated/model/employee-dto";
import { EsaMaterialButtonComponent, EsaMaterialFormFieldComponent } from "esa-material-form-field";
import { MatIconModule } from "@angular/material/icon";
import { CustomRichTextComponent } from "src/app/shared/components/custom-rich-text/custom-rich-text.component";
import { CustomRichTextTypeEnum } from "src/app/shared/generated/enum/custom-rich-text-type-enum";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { EmployeeMentoringUpsertDto } from "src/app/shared/generated/model/employee-mentoring-upsert-dto";
import { EmployeeMentoringService } from "src/app/shared/generated/api/employee-mentoring.service";
import { FormsModule } from "@angular/forms";
import { EmployeeMentoringDto } from "src/app/shared/generated/model/employee-mentoring-dto";
import { AlertService } from "src/app/shared/services/alert.service";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";

@Component({
    selector: "talentbridge-employee-mentoring",
    templateUrl: "./employee-mentoring.component.html",
    styleUrls: ["./employee-mentoring.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CustomRichTextComponent,
        EsaMaterialButtonComponent,
        MatIconModule,
        FormsModule,
        EsaMaterialFormFieldComponent
    ],
})
export class EmployeeMentoringComponent implements OnInit, OnDestroy {
    canEditText: boolean;
    public richTextTypeID: number = CustomRichTextTypeEnum.EmployeeMentoring;

    public currentUser: UserDto;
    public employee: EmployeeDto;
    public editMode = false;
    public originalEmployeeMentoringDto: EmployeeMentoringDto;
    public employeeMentoringUpsertDto: EmployeeMentoringUpsertDto;

    currentUserSubscription: Subscription;
    employeeMentoringGetSubscription: Subscription;
    employeeMentoringPutSubscription: Subscription;
    currentEmployeeSubscription: Subscription;

    yesNoOptions: any[] = [
        { value: false, label: "No" },
        { value: true, label: "Yes" }
    ];

    constructor(
        private employeeMentoringService: EmployeeMentoringService,
        private currentEmployeeService: CurrentEmployeeService,
        private authenticationService: AuthenticationService,
        private editViewEventService: EditViewEventService,
        private cdr: ChangeDetectorRef,
        private alertService: AlertService
    ) { }

    ngOnInit(): void {
        this.currentUserSubscription = this.authenticationService
            .getCurrentUser()
            .subscribe((result) => {
                this.currentUser = result;

                this.canEditText = this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CustomRichTextRights, RightsEnum.Update);
            });

            this.currentEmployeeSubscription = this.currentEmployeeService.getCurrentEmployee().subscribe((employee) => {
                this.employee = employee;
                this.employeeMentoringGetSubscription = this.employeeMentoringService.employeesUserIDMentoringGet(employee.UserID).subscribe((result) => {
                    this.originalEmployeeMentoringDto = result;
                    this.employeeMentoringUpsertDto = this.createEmployeeMentoringUpsertDto(result);

                    this.cdr.markForCheck();
                });
            });
    }

    ngOnDestroy(): void {
        this.currentUserSubscription?.unsubscribe();
        this.employeeMentoringGetSubscription?.unsubscribe();
        this.employeeMentoringPutSubscription?.unsubscribe();
        this.currentEmployeeSubscription?.unsubscribe();
    }

    onChangeMentee(value): void {
        if (!value && !this.employeeMentoringUpsertDto.BeenAMentor) {
            this.employeeMentoringUpsertDto.ContinueWithPreviousMentor = null;
            this.cdr.markForCheck();
        }
    }

    onChangeMentor(value): void {
        if (!this.employeeMentoringUpsertDto.BeenAMentee && !value) {
            this.employeeMentoringUpsertDto.ContinueWithPreviousMentor = null;
            this.cdr.markForCheck();
        }
    }

    createEmployeeMentoringUpsertDto(employeeMentoringDto: EmployeeMentoringDto) {
        const employeeMentoringUpsertDto = new EmployeeMentoringUpsertDto({
            BeenAMentor: employeeMentoringDto?.BeenAMentor,
            BeenAMentee: employeeMentoringDto?.BeenAMentee,
            InterestedInMentoring: employeeMentoringDto.InterestedInMentoring,
            InterestedInBeingMentored: employeeMentoringDto.InterestedInBeingMentored,
            ContinueWithPreviousMentor: employeeMentoringDto.ContinueWithPreviousMentor,
        });
        return employeeMentoringUpsertDto;
    }

    saveForm(form) {
        this.employeeMentoringPutSubscription = this.employeeMentoringService.employeesUserIDMentoringPut(this.employee.UserID, this.employeeMentoringUpsertDto).subscribe((result) => {
            this.alertService.pushAlert(new Alert("The employee was successfully updated.", AlertContext.Success), 5000);

            this.cancelEditMode();
        });
    }

    canEdit(employee: EmployeeDto): boolean {
        return (this.currentEmployeeService.canEditCurrentEmployee(this?.currentUser, employee) && !this.editMode);
    }

    enableEditMode() {
        this.editMode = true;
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.markForCheck();
    }

    cancelEditMode() {
        this.editMode = false;
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.markForCheck();
    }

    canExit() {
        if (this.editMode) {
            return (JSON.stringify(this.originalEmployeeMentoringDto) === JSON.stringify(this.employeeMentoringUpsertDto));
        } else {
            return true;
        }
    }
}
