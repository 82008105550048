<div class="detail-view">
    <div class="page-header has-actions-bar">
        <h1 class="page-title">Mentoring</h1>

        @if (employeeMentoringUpsertDto) {
            <div class="actions-bar">
                @if (canEdit(currentUser)) {
                    <esa-material-button
                        class="secondary-outline-button"
                        icon="edit"
                        label="Edit"
                        (click)="enableEditMode()"
                    >
                    </esa-material-button>
                }
            </div>
        }
    </div>

    <div class="copy copy-2">
        <custom-rich-text
            [customRichTextTypeID]="richTextTypeID"
            [userCanEdit]="canEditText"
            editorId="employeeMentoringPageText">
        </custom-rich-text>
        
    </div>

    @if (employeeMentoringUpsertDto) {
        <form class="form grid-12" #employeeMentoringForm="ngForm">
            <div class="g-col-6">
                <esa-material-ff
                    name="InterestedInMentoring"
                    [editMode]="editMode"
                    [required]="false"
                    label="Are you interested in being a mentor? "
                    type="dropdown"
                    [options]="yesNoOptions"
                    optionLabel="label"
                    optionValue="value"
                    [(ngModel)]="employeeMentoringUpsertDto.InterestedInMentoring">
                </esa-material-ff>
            </div>
            <div class="g-col-6">
                <esa-material-ff
                    name="InterestedInBeingMentored"
                    [editMode]="editMode"
                    [required]="false"
                    label="Are you interested in having a mentor?"
                    type="dropdown"
                    [options]="yesNoOptions"
                    optionLabel="label"
                    optionValue="value"
                    [(ngModel)]="employeeMentoringUpsertDto.InterestedInBeingMentored">
                </esa-material-ff>
            </div>
            <div class="g-col-6">
                <esa-material-ff
                    name="BeenAMentor"
                    [editMode]="editMode"
                    [required]="false"
                    label="Have you previously been a mentor?"
                    type="dropdown"
                    [options]="yesNoOptions"
                    optionLabel="label"
                    optionValue="value"
                    (valueChanged)="onChangeMentor($event)"
                    [(ngModel)]="employeeMentoringUpsertDto.BeenAMentor">
                </esa-material-ff>
            </div>
            <div class="g-col-6">
                <esa-material-ff
                    name="BeenAMentee"
                    [editMode]="editMode"
                    [required]="false"
                    label="Have you previously had a mentor?"
                    type="dropdown"
                    [options]="yesNoOptions"
                    optionLabel="label"
                    optionValue="value"
                    (valueChanged)="onChangeMentee($event)"
                    [(ngModel)]="employeeMentoringUpsertDto.BeenAMentee">
                </esa-material-ff>
            </div>
            <div class="g-col-4">
                <esa-material-ff
                    name="ContinueWithPreviousMentor"
                    [editMode]="editMode"
                    [required]="false"
                    label="If you have previously participated, would you like to continue with the same partner?"
                    type="dropdown"
                    [options]="yesNoOptions"
                    optionLabel="label"
                    optionValue="value"
                    [disabled]="!employeeMentoringUpsertDto.BeenAMentor && !employeeMentoringUpsertDto.BeenAMentee"
                    [(ngModel)]="employeeMentoringUpsertDto.ContinueWithPreviousMentor">
                </esa-material-ff>
            </div>


            @if (editMode) {
                <div class="g-col-12 actions-bar actions-bar__footer">
                    <esa-material-button
                        style="margin-right: 1rem"
                        type="primary"
                        label="Save"
                        icon="save"
                        [tooltip]="
                            !employeeMentoringForm.form.valid
                                ? 'You are missing required fields.'
                                : ''
                        "
                        (click)="saveForm(employeeMentoringForm)"
                        [disabled]="!employeeMentoringForm.form.valid"
                    >
                    </esa-material-button>
                    <esa-material-button
                        type="clear"
                        label="Cancel"
                        icon="cancel"
                        (click)="cancelEditMode()"
                    >
                    </esa-material-button>
                </div>
            }
        </form>
    }
</div>
