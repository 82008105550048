import { Routes } from '@angular/router';
import { NotFoundComponent, UnauthenticatedComponent, SubscriptionInsufficientComponent } from './shared/pages';
import { UserListComponent } from './pages/manage/user/user-list/user-list.component';
import { HomeIndexComponent } from './pages/home/home-index/home-index.component';
import { UserDetailComponent } from './pages/manage/user/user-detail/user-detail.component';
import { UserEditComponent } from './pages/manage/user/user-edit/user-edit.component';
import { LoginCallbackComponent } from './pages/login-callback/login-callback.component';
import { HelpComponent } from './pages/help/help.component';
import { CreateUserCallbackComponent } from './pages/create-user-callback/create-user-callback.component';
import { FieldDefinitionListComponent } from './pages/manage/field-definition/field-definition-list/field-definition-list.component';
import { FieldDefinitionEditComponent } from './pages/manage/field-definition/field-definition-edit/field-definition-edit.component';
import { CustomPageListComponent } from './pages/manage/custom-page/custom-page-list/custom-page-list.component';
import { CustomPageDetailComponent } from './pages/manage/custom-page/custom-page-detail/custom-page-detail.component';
import { CustomPageEditPropertiesComponent } from './pages/manage/custom-page/custom-page-edit-properties/custom-page-edit-properties.component';
import { MsalGuard } from '@azure/msal-angular';
import { UnsavedChangesGuard } from './shared/guards/unsaved-changes-guard';
import { DashboardManageComponent } from './pages/manage/dashboard-manage.component';
import { UserInviteComponent } from './pages/manage/user/user-invite/user-invite.component';
import { ProjectListComponent } from './pages/project/project-list/project-list.component';
import { ProjectCreateComponent } from './pages/project/project-create/project-create.component';
import { ProjectDetailComponent } from './pages/project/project-detail/project-detail.component';
import { ProjectDetailSummaryComponent } from './pages/project/project-detail/project-detail-summary/project-detail-summary.component';
import { ProjectFileListComponent } from "./pages/project/project-detail/project-file-list/project-file-list.component";
import { EmployeeListComponent } from './pages/employee/employee-list/employee-list.component';
import { EmployeeDetailComponent } from './pages/employee/employee-detail/employee-detail.component';
import { EmployeeDetailSummaryComponent } from './pages/employee/employee-detail/employee-detail-summary/employee-detail-summary.component';
import { QualificationListComponent } from './pages/qualification/qualification-list/qualification-list.component';
import { QualificationDetailComponent } from './pages/qualification/qualification-detail/qualification-detail.component';
import { QualificationDetailSummaryComponent } from './pages/qualification/qualification-detail/qualification-detail-summary/qualification-detail-summary.component';
import { QualificationCreateComponent } from './pages/qualification/qualification-create/qualification-create.component';
import { ProjectEmployeeListComponent } from './pages/project/project-detail/project-employee-list/project-employee-list.component';
import { EmployeeProjectListComponent } from './pages/employee/employee-detail/employee-project-list/employee-project-list.component';
import { EmployeeQualificationsComponent } from './pages/employee/employee-detail/employee-qualifications/employee-qualifications.component';
import { ClientListComponent } from './pages/client/client-list/client-list.component';
import { ClientDetailComponent } from './pages/client/client-detail/client-detail.component';
import { ClientDetailSummaryComponent } from './pages/client/client-detail/client-detail-summary/client-detail-summary.component';
import { ClientEmployeeListComponent } from './pages/client/client-detail/client-employee-list/client-employee-list.component';
import { ClientProjectListComponent } from './pages/client/client-detail/client-project-list/client-project-list.component';
import { EmployeeDirectReportsListComponent } from './pages/employee/employee-detail/employee-direct-reports-list/employee-direct-reports-list.component';
import { ConfigurationDetailComponent } from './pages/manage/configuration/configuration-detail/configuration-detail.component';
import { QualificationEmployeeListComponent } from './pages/qualification/qualification-detail/qualification-employee-list/qualification-employee-list.component';
import { ProjectPhotosComponent } from './pages/project/project-detail/project-photos/project-photos.component';
import { ServiceListComponent } from './pages/service/service-list/service-list.component';
import { ServiceCreateComponent } from './pages/service/service-create/service-create.component';
import { ServiceDetailComponent } from './pages/service/service-detail/service-detail.component';
import { ServiceDetailSummaryComponent } from './pages/service/service-detail/service-detail-summary/service-detail-summary.component';
import { ServiceCategoryListComponent } from './pages/service-category/service-category-list/service-category-list.component';
import { ServiceCategoryDetailComponent } from './pages/service-category/service-category-detail/service-category-detail.component';
import { ServiceCategoryDetailSummaryComponent } from './pages/service-category/service-category-detail/service-category-detail-summary/service-category-detail-summary.component';
import { ServiceCategoryCreateComponent } from './pages/service-category/service-category-create/service-category-create.component';
import { ServiceLineListComponent } from './pages/service-line/service-line-list/service-line-list.component';
import { ServiceLineDetailComponent } from './pages/service-line/service-line-detail/service-line-detail.component';
import { ServiceLineDetailSummaryComponent } from './pages/service-line/service-line-detail/service-line-detail-summary/service-line-detail-summary.component';
import { ServiceLineCreateComponent } from './pages/service-line/service-line-create/service-line-create.component';
import { QualificationReviewComponent } from './pages/manage/qualification/qualification-review/qualification-review.component';
import { EmployeeMentoringComponent } from './pages/employee/employee-detail/employee-mentoring/employee-mentoring.component';

export const routes: Routes = [
    {
        path: "custom-pages/:vanity-url",
        component: CustomPageDetailComponent,
        canDeactivate: [UnsavedChangesGuard],
    },

  { path: "manage", component: DashboardManageComponent, canActivate: [MsalGuard], children: [
    { path: "configuration", component: ConfigurationDetailComponent, canActivate: [MsalGuard] },
    { path: "users", component: UserListComponent, canActivate: [MsalGuard] },
    { path: "users/invite", component: UserInviteComponent, canActivate: [MsalGuard] },
    { path: "users/:id", component: UserDetailComponent, canActivate: [MsalGuard] },
    { path: "users/:id/edit", component: UserEditComponent, canActivate: [MsalGuard] },
    { path: "custom-pages", component: CustomPageListComponent, canActivate: [MsalGuard] },
    { path: "custom-pages/create", component: CustomPageEditPropertiesComponent, data: { create: true }, canActivate: [MsalGuard], canDeactivate: [UnsavedChangesGuard] },
    { path: "custom-pages/edit-properties/:vanity-url", component: CustomPageEditPropertiesComponent, canActivate: [MsalGuard], data: { create: false }, canDeactivate: [UnsavedChangesGuard] },
    { path: "labels-and-definitions/:id", component: FieldDefinitionEditComponent, canActivate: [MsalGuard], canDeactivate: [UnsavedChangesGuard] },
    { path: "labels-and-definitions", component: FieldDefinitionListComponent, canActivate: [MsalGuard] },
    { path: "qualification-review", component: QualificationReviewComponent, canActivate: [MsalGuard] },
  ] },
  { path: "projects", component: ProjectListComponent, canActivate: [MsalGuard] },
  {
    path: "projects/create", component: ProjectCreateComponent,
    canActivate: [MsalGuard],
    canDeactivate: [UnsavedChangesGuard]
  },
  {
    path: "projects/:id",
    component: ProjectDetailComponent,
    canActivate: [MsalGuard], 
    children: [
      {
        path: "",
        component: ProjectDetailSummaryComponent,
        canActivate: [MsalGuard],
        canDeactivate: [UnsavedChangesGuard]
      },
      {
        path: "employee-list",
        component: ProjectEmployeeListComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "photos",
        component: ProjectPhotosComponent,
        canActivate: [MsalGuard],
        canDeactivate: [UnsavedChangesGuard]
      },
      {
        path: "file-list",
        component: ProjectFileListComponent,
        canActivate: [MsalGuard],
        canDeactivate: [UnsavedChangesGuard]
      }
    ]
  },
  { path: "employees", component: EmployeeListComponent, canActivate: [MsalGuard] },
  {
    path: "employees/:id",
    component: EmployeeDetailComponent,
    canActivate: [MsalGuard], 
    children: [
      {
        path: "",
        component: EmployeeDetailSummaryComponent,
        canActivate: [MsalGuard],
        canDeactivate: [UnsavedChangesGuard]
      },
      {
        path: "project-list",
        component: EmployeeProjectListComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "qualifications",
        component: EmployeeQualificationsComponent,
        canActivate: [MsalGuard],
        canDeactivate: [UnsavedChangesGuard]
      },
      {
        path: "direct-reports",
        component: EmployeeDirectReportsListComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "mentoring",
        component: EmployeeMentoringComponent,
        canActivate: [MsalGuard],
        canDeactivate: [UnsavedChangesGuard]
      },
    ]
  },
  { path: "clients", component: ClientListComponent, canActivate: [MsalGuard] },
  {
    path: "clients/:id",
    component: ClientDetailComponent,
    canActivate: [MsalGuard], 
    children: [
      {
        path: "",
        component: ClientDetailSummaryComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "employees",
        component: ClientEmployeeListComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "projects",
        component: ClientProjectListComponent,
        canActivate: [MsalGuard],
      },
    ]
  },
  { path: "qualifications", component: QualificationListComponent, canActivate: [MsalGuard] },
  { path: "qualifications/create", component: QualificationCreateComponent, canActivate: [MsalGuard], canDeactivate: [UnsavedChangesGuard] },
  {
      path: "qualifications/:id",
      component: QualificationDetailComponent,
      canActivate: [MsalGuard], 
      children: [
        {
          path: "",
          component: QualificationDetailSummaryComponent,
          canActivate: [MsalGuard],
          canDeactivate: [UnsavedChangesGuard]
        },
        {
          path: "employees",
          component: QualificationEmployeeListComponent,
          canActivate: [MsalGuard],
        },
      ]
  },
  { path: "services", component: ServiceListComponent, canActivate: [MsalGuard] },
  { path: "services/create", component: ServiceCreateComponent, canActivate: [MsalGuard], canDeactivate: [UnsavedChangesGuard] },
  {
    path: "services/:id",
    component: ServiceDetailComponent,
    canActivate: [MsalGuard], 
    children: [
      {
        path: "",
        component: ServiceDetailSummaryComponent,
        canActivate: [MsalGuard],
        canDeactivate: [UnsavedChangesGuard]
      },
    ]
  },
  { path: "service-categories", component: ServiceCategoryListComponent, canActivate: [MsalGuard] },
  { path: "service-categories/create", component: ServiceCategoryCreateComponent, canActivate: [MsalGuard], canDeactivate: [UnsavedChangesGuard] },
  {
    path: "service-categories/:id",
    component: ServiceCategoryDetailComponent,
    canActivate: [MsalGuard], 
    children: [
      {
        path: "",
        component: ServiceCategoryDetailSummaryComponent,
        canActivate: [MsalGuard],
        canDeactivate: [UnsavedChangesGuard]
      },
    ]
  },
  { path: "service-lines", component: ServiceLineListComponent, canActivate: [MsalGuard] },
  { path: "service-lines/create", component: ServiceLineCreateComponent, canActivate: [MsalGuard], canDeactivate: [UnsavedChangesGuard] },
  {
    path: "service-lines/:id",
    component: ServiceLineDetailComponent,
    canActivate: [MsalGuard], 
    children: [
      {
        path: "",
        component: ServiceLineDetailSummaryComponent,
        canActivate: [MsalGuard],
        canDeactivate: [UnsavedChangesGuard]
      },
    ]
  },
  { path: "help", component: HelpComponent, canActivate: [MsalGuard] },
  { path: "create-user-callback", component: CreateUserCallbackComponent },
  { path: "not-found", component: NotFoundComponent },
  { path: 'subscription-insufficient', component: SubscriptionInsufficientComponent },
  { path: 'unauthenticated', component: UnauthenticatedComponent },
  { path: "signin-oidc", component: LoginCallbackComponent },
  { path: "", component: HomeIndexComponent, canActivate: [MsalGuard]},
  { path: '**', component: NotFoundComponent }
];
