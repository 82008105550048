<div class="list-view">
    <div class="page-header">
        <div class="actions-bar flex-between">
            <h1 class="page-title">Skills & Qualifications Review</h1>
        </div>
        <div class="copy copy-2">
            <p>A list of Skills & Qualifications suggested by Employee-Owners.  You can approve or reject them.  Upon approval, they become available for Employee-Owners to add to their profile.</p>
        </div>
    </div>

    <div class="qualifications-to-review">
        <div class="table-responsive">
            <talentbridge-grid
                #qualificationReviewGrid
                height="250px"
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                downloadFileName="reviewQualifications"
                recordNamePlural="Qualifications to Review"
                (gridReady)="onQualificationReviewGridReady($event)"
                [sizeColumnsToFitGrid]="true"
                [colIDsToExclude]="['review']">
            >
            </talentbridge-grid>
        </div>
    </div>
    
    <hr class="section-spacer" />

    <div class="rejected-suggestions">
        <div class="section-header">
            <h2 class="section-title">Rejected Suggestions</h2>
            <div class="copy copy-2">
                <p>
                    A list of previously rejected qualification suggestions, with the reason why they were not approved.
                </p>
            </div>
        </div>

        <div class="table-responsive">
            <talentbridge-grid
                #qualificationRejectedGrid
                height="250px"
                [rowData]="rejectedRowData"
                [columnDefs]="rejectedColumnDefs"
                downloadFileName="rejectedQualifications"
                recordNamePlural="Rejected Qualifications"
                [sizeColumnsToFitGrid]="true"
            >
            </talentbridge-grid>
        </div>
    </div>
</div>
