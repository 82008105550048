<div class="list-view">
   
    <div class="page-header">
        <div class="actions-bar flex-between">
            <h1 class="page-title has-icon">
                <mat-icon fontSet="material-icons-outlined">eco</mat-icon>
                Projects
            </h1>
        </div>
    </div>

    <div class="copy copy-2">
        <custom-rich-text
            [customRichTextTypeID]="richTextTypeID"
            [userCanEdit]="canEditText"
            editorId="projectListPageText">
        </custom-rich-text>
    </div>

    <div class="filter-bar">
        <div class="filter-bar__header">
            <mat-icon>tune</mat-icon>
            <h3>Filters</h3>
        </div>

        @if (!searchLocation) {
            <esa-material-button
                title="Location"
                label="Location"
                icon="public"
                type="secondary"
                (click)="filterLocation()"
            >
            </esa-material-button>
        } @else {
            <esa-material-button
                [title]="
                    searchLocation.display_name +
                    (searchDistance > 0
                        ? ' (' + searchDistance + ' miles)'
                        : '')
                "
                [label]="
                    searchLocation.display_name +
                    (searchDistance > 0
                        ? ' (' + searchDistance + ' miles)'
                        : '')
                "
                icon="public"
                type="primary"
                (click)="filterLocation()"
            >
            </esa-material-button>
        }
    </div>
    <mat-accordion>
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title> Projects Map </mat-panel-title>
            </mat-expansion-panel-header>

            <esa-material-ff
                name="checkViewClusteredPoints"
                [editMode]="true"
                label="View Clustered Points"
                type="checkbox"
                [(ngModel)]="viewClusteredPoints"
                (valueChanged)="toggleViewClusteredPoints($event)">
            </esa-material-ff>

            <map #MapComponent 
                (onMapLoad)="mapInit($event)" 
                [useClusters]="viewClusteredPoints">
            </map>
        </mat-expansion-panel>
    </mat-accordion>

    <div>
        <div class="table-responsive">
            <talentbridge-grid
                #ProjectsGrid
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                downloadFileName="projects"
                recordNamePlural="Projects"
                rowSelection="single"
                [pagination]="false"
                (gridReady)="onProjectsGridReady($event)"
                (selectionChanged)="onSelectionChanged($event)"
                (filterChanged)="onFilterChanged($event)"
                (filterCleared)="clearFilters()"
                [colIDsToExclude]="['map']">
            </talentbridge-grid>
        </div>              
    </div>
</div>
