<div class="list-view">
    <div class="page-header has-actions-bar">
        <h1 class="page-title has-icon">
            <mat-icon fontSet="material-icons-outlined">military_tech</mat-icon>
            Skills & Qualifications
        </h1>

        <div class="actions-bar flex-between">
            @if (canCreate) {
                <esa-material-button
                    class="secondary-outline-button"
                    label="Qualification"
                    icon="add"
                    type="primary"
                    (click)="navigateToQualificationCreatePage()"
                >
                </esa-material-button>
            } @else {
                <esa-material-button
                    class="secondary-outline-button"
                    label="Suggest a new qualification"
                    icon="add"
                    type="primary"
                    (click)="openQualificationSuggestDialog()"
                >
                </esa-material-button>
            }
        </div>
    </div>

    <div class="copy copy-2">
        <custom-rich-text
            [customRichTextTypeID]="richTextTypeID"
            [userCanEdit]="canEditText"
            editorId="qualificationListPageText">
        </custom-rich-text>
    </div>

    <div class="table-responsive">
        @if(currentUser) {
            <talentbridge-grid
                #qualificationsGrid
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                downloadFileName="qualifications"
                recordNamePlural="Qualifications"
                (gridReady)="onQualificationsGridReady($event)"
            >
            </talentbridge-grid>
        }
    </div>
</div>
