<div class="tb-dialog">
    <div class="tb-dialog__header">
        <h2 mat-dialog-title>
            <mat-icon>military_tech</mat-icon>
            Add Skill or Qualification
        </h2>

        <div class="tb-dialog__close" (click)="closeDialog(true)">
            <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M11.7342 0.274897C11.65 0.190519 11.55 0.123576 11.4399 0.0779014C11.3298 0.0322267 11.2117 0.00871629 11.0925 0.00871629C10.9733 0.00871629 10.8553 0.0322267 10.7452 0.0779014C10.6351 0.123576 10.535 0.190519 10.4508 0.274897L6 4.71663L1.54916 0.265794C1.4649 0.181527 1.36486 0.114683 1.25476 0.0690775C1.14466 0.0234724 1.02665 8.879e-10 0.90748 0C0.788308 -8.879e-10 0.670302 0.0234724 0.560202 0.0690775C0.450101 0.114683 0.350062 0.181527 0.265794 0.265794C0.181527 0.350062 0.114683 0.450101 0.0690775 0.560202C0.0234724 0.670302 -8.879e-10 0.788308 0 0.90748C8.879e-10 1.02665 0.0234724 1.14466 0.0690775 1.25476C0.114683 1.36486 0.181527 1.4649 0.265794 1.54916L4.71663 6L0.265794 10.4508C0.181527 10.5351 0.114683 10.6351 0.0690775 10.7452C0.0234724 10.8553 0 10.9733 0 11.0925C0 11.2117 0.0234724 11.3297 0.0690775 11.4398C0.114683 11.5499 0.181527 11.6499 0.265794 11.7342C0.350062 11.8185 0.450101 11.8853 0.560202 11.9309C0.670302 11.9765 0.788308 12 0.90748 12C1.02665 12 1.14466 11.9765 1.25476 11.9309C1.36486 11.8853 1.4649 11.8185 1.54916 11.7342L6 7.28337L10.4508 11.7342C10.5351 11.8185 10.6351 11.8853 10.7452 11.9309C10.8553 11.9765 10.9733 12 11.0925 12C11.2117 12 11.3297 11.9765 11.4398 11.9309C11.5499 11.8853 11.6499 11.8185 11.7342 11.7342C11.8185 11.6499 11.8853 11.5499 11.9309 11.4398C11.9765 11.3297 12 11.2117 12 11.0925C12 10.9733 11.9765 10.8553 11.9309 10.7452C11.8853 10.6351 11.8185 10.5351 11.7342 10.4508L7.28337 6L11.7342 1.54916C12.0801 1.20329 12.0801 0.620769 11.7342 0.274897Z"
                    fill="#7C7C7C"
                />
            </svg>
        </div>
    </div>

    <div class="tb-dialog__body">
        <mat-dialog-content>
            <div class="cta">
                Add the skill <strong>{{ data.Qualification.Name }}</strong> to 
                <img class="user-button__image" src="{{ mainAppApiUrl }}/users/{{ currentUser.UserID }}/photoThumbnail?uid={{ currentUser.UserGuid }}&secure={{ currentUser.FileAccessToken }}" />
                <strong>{{ currentUser?.LoginName }}'s</strong>
                profile.    

            </div>
            @if (this.data.Qualification.QualificationType.RequiresExperienceLevel) {
                <p class="tb-dialog__label">
                    Set your experience level:
                </p>
                <mat-button-toggle-group [(ngModel)]="selectedExperienceLevel" aria-label="Experience Level">
                    @for (expLevel of experienceLevels; track $index) {
                        <mat-button-toggle [value]="expLevel.ExperienceLevelID">
                            <div class="stars">
                                @for (_ of [].constructor(expLevel.ExperienceLevelID); track _) {
                                    <mat-icon [title]="expLevel.DisplayName">star_rate</mat-icon>
                                }
                            </div>
                            <div class="name">{{expLevel.Name}}</div>
                            
                            <span class="description">{{expLevel.$Description}}</span>
                        </mat-button-toggle>

                        @if ($index % 2 !== 0) {
                            <div style="display:block;"></div>
                        }
                    }
                </mat-button-toggle-group>
            }
        </mat-dialog-content>
    </div>

    <div class="tb-dialog__footer">
        <div class="tb-dialog__actions-list">
            <mat-dialog-actions align="end">
                <esa-material-button
                    label="Submit"
                    type="secondary"
                    [disabled]="this.data.Qualification.QualificationType.RequiresExperienceLevel && this.selectedExperienceLevel == null"
                    (click)="closeDialog(false)"
                ></esa-material-button>
            </mat-dialog-actions>
        </div>
    </div>
</div>
