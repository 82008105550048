import {
    MAT_DIALOG_DATA,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogRef,
} from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { CurrentQualificationService } from 'src/app/services/current-qualification.service';
import { QualificationTypeService } from 'src/app/shared/generated/api/qualification-type.service';
import { QualificationDto } from 'src/app/shared/generated/model/qualification-dto';
import { QualificationUpsertDto } from 'src/app/shared/generated/model/qualification-upsert-dto';
import { EsaMaterialButtonComponent, EsaMaterialFormFieldComponent } from 'esa-material-form-field';
import { forkJoin } from 'rxjs';
import { ServiceLineService } from 'src/app/shared/generated/api/service-line.service';
import { ServiceCategoryService } from 'src/app/shared/generated/api/service-category.service';
import { ServiceService } from 'src/app/shared/generated/api/service.service';

@Component({
    selector: "talentbridge-qualification-suggest-dialog",
    templateUrl: "qualification-suggest-dialog.component.html",
    styleUrls: ["./qualification-suggest-dialog.component.scss"],
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        MatIconModule,
        EsaMaterialButtonComponent,
        EsaMaterialFormFieldComponent,
        FormsModule
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QualificationSuggestDialog {
    @ViewChild("qualificationSuggestForm", { read: NgForm }) form: NgForm;

    qualificationUpsertDto: QualificationUpsertDto;

    selectedServiceLineID: number;
    selectedServiceCategoryID: number
  
    public serviceLines: any;
    public serviceCategories: any;
    public services: any;
    public qualificationTypes: any;
    
    public serviceCategoriesFiltered: any;
    public servicesFiltered: any;

    constructor(
        private currentQualificationService: CurrentQualificationService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<QualificationSuggestDialog>,
        private qualificationTypeService: QualificationTypeService,
        private serviceService: ServiceService,
        private serviceCategoryService: ServiceCategoryService,
        private serviceLineService: ServiceLineService,
        private cdr: ChangeDetectorRef,
    ) {}
    
    ngOnInit(): void {
        forkJoin([
            this.serviceLineService.serviceLinesGet(), 
            this.serviceCategoryService.serviceCategoriesGet(), 
            this.serviceService.servicesGet(),
            this.qualificationTypeService.qualificationTypesGet()
        ]).subscribe(([serviceLines, serviceCategories, services, qualificationTypes]) => {  
            this.serviceLines = serviceLines; 
            this.serviceCategories = serviceCategories; 
            this.services = services; 
            this.qualificationTypes = qualificationTypes; 
            this.qualificationUpsertDto = this.currentQualificationService.createQualificationDto(new QualificationDto());
            this.cdr.detectChanges();
        });
    }

    closeDialog(cancel: boolean) {
        if (cancel) {
            this.dialogRef.close(null);
            return;
        }

        this.dialogRef.close(this.qualificationUpsertDto);
    }

    filterServiceLine() {
        this.serviceCategoriesFiltered = this.serviceCategories.filter((serviceCategory) => serviceCategory.ServiceLine.ServiceLineID === this.selectedServiceLineID);
        if (this.serviceCategoriesFiltered.filter((serviceCategory) => serviceCategory.ServiceCategoryID === this.selectedServiceCategoryID).length === 0) {
            this.selectedServiceCategoryID = null;
            this.qualificationUpsertDto.ServiceID = null;
            this.servicesFiltered = [];
        }
    }
    
    filterServiceCategory() {
        this.servicesFiltered = this.services.filter((service) => service.ServiceCategory.ServiceCategoryID === this.selectedServiceCategoryID);
        if (this.servicesFiltered.filter((service) => service.ServiceID === this.qualificationUpsertDto.ServiceID).length === 0) {
            this.qualificationUpsertDto.ServiceID = null;
        }
    }
      
    getTooltip() {
        if (this.qualificationUpsertDto.Name?.length > 200) {
            return "Name field must be 200 characters or less."
        }
    
        return 'You are missing required fields.';
    }
}
