import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { Router } from "@angular/router";
import { ColDef, GridApi, SelectionChangedEvent } from "ag-grid-community";
import { EsaMaterialButtonComponent, EsaMaterialFormFieldComponent } from "esa-material-form-field";
import * as L from 'leaflet';
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { CustomRichTextComponent } from "src/app/shared/components/custom-rich-text/custom-rich-text.component";
import { LocationFilterDialog } from "src/app/shared/components/location-filter-dialog/location-filter-dialog.component";
import { MapMarkerComponent } from 'src/app/shared/components/map/map-marker/map-marker.component';
import { MapComponent, MapInitEvent } from 'src/app/shared/components/map/map.component';
import { TalentBridgeGridComponent } from "src/app/shared/components/talentbridge-grid/talentbridge-grid.component";
import { ProjectService } from "src/app/shared/generated/api/project.service";
import { CustomRichTextTypeEnum } from "src/app/shared/generated/enum/custom-rich-text-type-enum";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { ProjectSummaryDto } from "src/app/shared/generated/model/project-summary-dto";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { LocationHelper } from "src/app/shared/helpers/location-helper";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { DateColumnCreatorService } from "src/app/shared/services/date-column-creator/date-column-creator.service";

@Component({
    selector: "talentbridge-project-list",
    templateUrl: "./project-list.component.html",
    styleUrls: ["./project-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CustomRichTextComponent,
        FormsModule,
        EsaMaterialButtonComponent,
        EsaMaterialFormFieldComponent,
        TalentBridgeGridComponent, 
        MatIconModule,
        MapComponent,
        MatExpansionModule
    ],
})
export class ProjectListComponent implements OnInit {
    @ViewChild('MapComponent') mapComponent: MapComponent;

    mapMarkers: MapMarkerComponent[] = [];

    public currentUser: UserDto;
    canEditText: boolean;
    viewClusteredPoints: boolean = true;
    public richTextTypeID : number = CustomRichTextTypeEnum.ProjectIndex;
    public projects: ProjectSummaryDto[];
    public projectsWithGeoData: ProjectSummaryDto[];
    public filteredProjectsWithGeoData: ProjectSummaryDto[];

    public rowData = [];
    public columnDefs: ColDef[];

    gridApi: GridApi;

    user: Subscription;
    getProjectRequest: Subscription;

    searchLocation: any;
    searchDistance: number = -1;

    constructor(
        private projectService: ProjectService,
        private authenticationService: AuthenticationService,
        private dateColumnCreator: DateColumnCreatorService,
        private cdr: ChangeDetectorRef,
        private router: Router,
        public dialog: MatDialog
    ) {
        this.columnDefs = [
            {
                headerName: "D Number",
                headerTooltip: "D Number",
                valueGetter: function (params: any) {
                    return {
                            LinkValue: params.data.ProjectID,
                            LinkDisplay: params.data.DNumber,
                        };
                },
                cellRendererSelector: (params) => {
                    return {
                            component: LinkRendererComponent,
                            params: { inRouterLink: "/projects/" },
                        };
                },
                filterValueGetter: function (params: any) {
                    return params.data.DNumber;
                },
                comparator: function (
                    linkA,
                    linkB,
                    nodeA,
                    nodeB,
                    isDescending
                ) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                flex: 2,
                sort: "asc",
                tooltipField: "DNumber",
            },
            {
                headerName: "Project Name",
                field: "Name",
                flex: 2,
                tooltipField: "Name",
            },
            {
                headerName: "Client Name",
                field: "ClientName",
                flex: 2,
                tooltipField: "ClientName",
            },
            {
                headerName: "Project Manager",
                field: "ProjectManager",
                flex: 2,
                tooltipField: "ProjectManager",
            },
            {
                headerName: "Status",
                field: "Status",
                flex: 1,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "Status",
                },
                tooltipField: "Status",
            },
            {
                headerName: "Location",
                field: "Location",
                flex: 2,
                tooltipField: "Location",
            },
            {
                headerName: "Latitude",
                field: "Latitude",
                flex: 2,
                tooltipField: "Latitude",
                cellDataType: "number"
            },
            {
                headerName: "Longitude",
                field: "Longitude",
                flex: 2,
                tooltipField: "Longitude",
                cellDataType: "number"
            },
            {
                headerName: "Map",
                field: "HasGeoSpatialData",
                cellRenderer: (params) => {
                    return params.value ? "<mat-icon class='mat-icon material-icons mat-icon-no-color'>location_on</mat-icon>" : "<mat-icon class='mat-icon material-icons mat-icon-no-color'>location_off</mat-icon>";
                },
                flex: 1,
                colId: "map"
            },
            {
                headerName: "Distance",
                field: "$HaversineDistance",
                valueFormatter: p => p.value == undefined ? "" : p.value.toFixed(2) + " miles",
                flex: 2,
                wrapText: true,
            },
            this.dateColumnCreator.createDateColumnDef(
                "Start Date",
                "StartDate",
                "M/dd/YYYY"
            ),
            this.dateColumnCreator.createDateColumnDef(
                "End Date",
                "EndDate",
                "M/dd/YYYY"
            ),
        ];
    }

    ngOnInit(): void {
        this.user = this.authenticationService
            .getCurrentUser()
            .subscribe((result) => {
                this.currentUser = result;

                this.canEditText = this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CustomRichTextRights, RightsEnum.Update);

                this.cdr.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this.user.unsubscribe();
        this.getProjectRequest.unsubscribe();
    }

    navigateToProjectCreatePage() {
        this.router.navigate(["/projects/create"]);
    }

    onProjectsGridReady(gridEvent) {
        this.gridApi = gridEvent.api;
        this.gridApi.showLoadingOverlay();

        this.gridApi.setGridOption("isExternalFilterPresent", this.isExternalFilterPresent.bind(this));
        this.gridApi.setGridOption("doesExternalFilterPass", this.doesExternalFilterPass.bind(this));

        this.getProjectRequest = this.projectService
            .projectsGet()
            .subscribe((results) => {
                this.rowData = results;

                this.gridApi.hideOverlay();
                this.projects = results;
                this.projectsWithGeoData = results.filter((p) => p.HasGeoSpatialData);
                this.filteredProjectsWithGeoData = this.projectsWithGeoData;
                this.recreateMarkers();
                this.cdr.markForCheck();
            });
    }

    onFilterChanged(gridEvent) {
        this.filteredProjectsWithGeoData = gridEvent.api.rowModel.rowsToDisplay.map((r) => r.data).filter((p) => p.HasGeoSpatialData);
        this.recreateMarkers();
    }

    onSelectionChanged($event: SelectionChangedEvent<any,any>) {
        const selectedRows = $event.api.getSelectedRows();
        const selectedRow = selectedRows[0];
        // swap the related map marker so it looks highlighted
        this.mapMarkers.forEach(marker => {
            if (marker.marker._icon) {
                if (marker.latitude === selectedRow.Latitude && marker.longitude === selectedRow.Longitude) {
                    marker.marker._icon.classList.add('highlight');
                } 
                else {  
                    marker.marker._icon.classList.remove('highlight');
                }
            }
        });
        if (selectedRow.HasGeoSpatialData) {
            this.map.flyTo(new L.LatLng(selectedRow.Latitude, selectedRow.Longitude), 12);
        }
    }

    get canCreateProject(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.ProjectRights, RightsEnum.Create);
    }

    public map : L.Map;
    public layerControl: L.Control.Layers;
  
    mapInit(mapInitEvent: MapInitEvent) {
      this.map = mapInitEvent.map;
      this.layerControl = mapInitEvent.layerControl;
      this.cdr.markForCheck();
    }

    onMarkerClick($event: Event, obj: any) {
        this.goToSelected(obj.ProjectID);
    }
    
    goToSelected(projectID) {
        this.gridApi.forEachNode((node) => {
            if (node.data.ProjectID === projectID) {
                node.setSelected(true, true);
                this.gridApi.ensureNodeVisible(node, 'top');
                this.gridApi.setFocusedCell(node.rowIndex, "DNumber", 'top');
            }
        });
    }

    recreateMarkers() {
        this.mapMarkers.forEach(marker => { this.map.removeLayer(marker.marker); });
        this.mapMarkers = [];
        this.map.markerClusterGroup.clearLayers(); 

        this.filteredProjectsWithGeoData.forEach(element => {
            var mapMarker = new MapMarkerComponent();
            mapMarker.latitude = element.Latitude;
            mapMarker.longitude = element.Longitude;
            mapMarker.label = element.Name;
            mapMarker.map = this.map;
            mapMarker.useClusteredPoints = this.viewClusteredPoints;
            mapMarker.onClick.subscribe((e) => { this.onMarkerClick(e, element); });
            mapMarker.ngOnInit();

            this.mapMarkers.push(mapMarker);
        });

        this.cdr.markForCheck();
    }

    toggleViewClusteredPoints(val) {
        this.viewClusteredPoints = val;
        this.mapComponent.viewClusteredPoints = val;
        if (val) {
            this.map.addLayer(this.map.markerClusterGroup);
        }
        else {
            this.map.removeLayer(this.map.markerClusterGroup);
        }
        this.recreateMarkers();
    }

    isExternalFilterPresent(): boolean {
        return this.searchLocation;
    }

    doesExternalFilterPass(node: any): boolean {
        var passes = true;
        if (node.data) {
            if (this.searchDistance > 0) {
                var distance = node.data.$HaversineDistance;
                passes = passes && (distance <= this.searchDistance);
            }
        }
        return passes;
    }

    filterLocation() {
        const dialogRef = this.dialog.open(LocationFilterDialog, {
            data: {
                location: this.searchLocation,
                distance: this.searchDistance,
            },
        });
        
        return dialogRef.afterClosed().subscribe((data) => {
            if (data) {
                if (data.location) {
                    this.searchLocation = data.location;
                    this.searchDistance = data.distance;

                    this.rowData.forEach((row) => {
                        row.$HaversineDistance = LocationHelper.haversineDistance(this.searchLocation.lat, this.searchLocation.lon, row.Latitude, row.Longitude);
                    }); 
                    
                    this.gridApi!.applyColumnState({
                        state: [{ colId: "$HaversineDistance", sort: "asc" }],
                        defaultState: { sort: null },
                    });
                }
                else {
                    this.searchLocation = null;   
                    this.rowData.forEach((row) => {
                        row.$HaversineDistance = null;
                    });
                }

                this.updateColumnVisiblity();
                this.cdr.markForCheck();
            }
        });
    }

    clearFilters() {
        this.gridApi.setFilterModel(null);
        this.searchLocation = null;
        this.searchDistance = -1;
        this.rowData.forEach((employee) => {
            employee.$HaversineDistance = null;
        });
        this.updateColumnVisiblity();
        this.cdr.markForCheck();
    }


    updateColumnVisiblity() { 
        this.gridApi.setColumnsVisible(["$HaversineDistance"], false);

        if (this.searchLocation) {
            this.gridApi.setColumnsVisible(["$HaversineDistance"], true);
        }

        this.gridApi.onFilterChanged();
    }

}
