//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[Role]

export enum RoleEnum {
  Admin = 1,
  NoAccess = 2,
  Normal = 3,
  QualificationEditor = 4,
}
