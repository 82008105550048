import { Component, OnDestroy, OnInit } from "@angular/core";
import { RouterLink, RouterLinkActive, RouterOutlet } from "@angular/router";
import { AuthenticationService } from "../../services/authentication.service";
import { UserDto } from "../../shared/generated/model/user-dto";
import { Subscription } from "rxjs";
import { PermissionEnum } from "../../shared/generated/enum/permission-enum";
import { RightsEnum } from "../../shared/models/enums/rights.enum";

@Component({
    selector: "talentbridge-dashboard-manage",
    templateUrl: "./dashboard-manage.component.html",
    styleUrls: ["./dashboard-manage.component.scss"],
    standalone: true,
    imports: [
    RouterLink,
    RouterLinkActive,
    RouterOutlet
],
})
export class DashboardManageComponent implements OnInit, OnDestroy {
    currentUser: UserDto;
    userCanEditConfiguration: boolean;
    userCanEditUsers: boolean;
    userCanEditFieldDefinitions: boolean;
    userCanEditCustomPages: boolean;
    userCanEditQualifications: boolean;
    hideText: boolean = false;
    currentUserSub: Subscription;
    constructor(private authenticationService: AuthenticationService) {}

    ngOnInit(): void {
        this.currentUserSub = this.authenticationService
            .getCurrentUser()
            .subscribe((result) => {
                this.currentUser = result;
                this.userCanEditConfiguration = this.authenticationService.hasPermission(this.currentUser, PermissionEnum.AdminConfigurationRights, RightsEnum.Update);
                this.userCanEditUsers = this.authenticationService.hasPermission(this.currentUser, PermissionEnum.UserRights, RightsEnum.Update);
                this.userCanEditFieldDefinitions = this.authenticationService.hasPermission(this.currentUser, PermissionEnum.FieldDefinitionRights, RightsEnum.Update);
                this.userCanEditCustomPages = this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CustomPageRights, RightsEnum.Update);
                this.userCanEditQualifications = this.authenticationService.hasPermission(this.currentUser, PermissionEnum.QualificationRights, RightsEnum.Update);
            });
    }

    onActivate(e: any) {
        if (e) {
            this.hideText = true;
        }
    }

    onDeactivate(e: any) {
        if (e) {
            this.hideText = false;
        }
    }

    ngOnDestroy(): void {
        this.currentUserSub?.unsubscribe();
    }
}
