

export class LocationHelper {
    
    public static haversineDistance(lat1: number, lon1: number, lat2: number, lon2: number, unit: 'km' | 'miles' = 'miles'): number {
        const R = unit === 'km' ? 6371 : 3959; // Earth's radius in kilometers or miles
        
        const toRadians = (deg: number) => (deg * Math.PI) / 180;
        
        const dLat = toRadians(lat2 - lat1);
        const dLon = toRadians(lon2 - lon1);
        
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRadians(lat1)) *
            Math.cos(toRadians(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        
        return R * c;
    }
}